var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"taskNumber"}},[_c('el-input',{attrs:{"placeholder":"任务单ID","title":"任务单ID"},model:{value:(_vm.searchForm.taskNumber),callback:function ($$v) {_vm.$set(_vm.searchForm, "taskNumber", $$v)},expression:"searchForm.taskNumber"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpName"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpEnum,"placeholder":"客户名称","title":"客户名称","props":{
                id: 'corpId',
                label: 'corpName'
              },"virtual":"","clearable":"","remote":"","is-title":true},on:{"filterChange":_vm.corpHandleFilter},model:{value:(_vm.searchForm.corpId),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpId", $$v)},expression:"searchForm.corpId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpAttrId"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpAtrrList,"placeholder":"客户属性","title":"客户属性","props":{
                id: 'id',
                label: 'attrValueDesc'
              },"clearable":""},model:{value:(_vm.searchForm.corpAttrId),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpAttrId", $$v)},expression:"searchForm.corpAttrId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--2",attrs:{"prop":"expectTime"}},[_c('e6-date-picker',{ref:"effectiveDatePicker",attrs:{"popper-class":"no-atTheMoment","label":"统计时间段","title":"统计时间段","picker-options":_vm.pickerOptions('searchForm.expectTime'),"default-time":['00:00:00', '23:59:59'],"start-placeholder":"统计时间（始）","end-placeholder":"统计时间（止）"},model:{value:(_vm.searchForm.expectTime),callback:function ($$v) {_vm.$set(_vm.searchForm, "expectTime", $$v)},expression:"searchForm.expectTime"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"elTable",staticClass:"elTable",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"序号","width":"50","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"}})})],2)],1),_vm._v(" "),_c('section',{ref:"paginationWrapper",staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }